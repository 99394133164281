import React from 'react';

class NetworkIcon extends React.Component {

    render() {
        return (
            <svg className={this.props.className} width="26" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.77 6.76L6.23 5.48.82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24zM7 13h2v-2H7v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52z" />
                <mask id="network" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.77 6.76L6.23 5.48.82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24zM7 13h2v-2H7v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52z" />
                </mask>
                <g mask="url(#network)">
                    <rect width="20" height="20"/>
                </g>
            </svg>
        );
    }
}

export default NetworkIcon;
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loading.module.scss';

class Loading extends React.Component {
    static propTypes = {
        size: PropTypes.number.isRequired
    };

    static defaultProps = {
        size: 21
    };

    render() {
        return (
            <div className={cx(s.loadingOverlay, this.props.className)}>
                <div className={cx(s.loadingContainer, this.props.className)}>
                    <div className={cx(s.loadingText, this.props.className)}>{this.props.message}</div>
                    <div className={cx(s.loadingWidget, this.props.className)} />
                </div>
            </div> 
        );
    }
}

export default Loading;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Progress, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { dismissAlert } from '../../actions/alerts';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup';

import { changeActiveSidebarItem, closeSidebar, openSidebar } from '../../actions/navigation';
import { logoutUser } from '../../actions/user';
import HomeIcon from '../Icons/SidebarIcons/HomeIcon';
import TypographyIcon from '../Icons/SidebarIcons/TypographyIcon';
import TablesIcon from '../Icons/SidebarIcons/TablesIcon';
import UsersIcon from '../Icons/SidebarIcons/UsersIcon';
import BaseIcon from '../Icons/SidebarIcons/BaseIcon';
import NetworkIcon from '../Icons/SidebarIcons/NetworkIcon';
import NotificationsIcon from '../Icons/SidebarIcons/NotificationsIcon';
import ComponentsIcon from '../Icons/SidebarIcons/ComponentsIcon';



class Sidebar extends React.Component {
    static propTypes = {
        sidebarStatic: PropTypes.bool,
        sidebarOpened: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        activeItem: PropTypes.string,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        sidebarStatic: false,
        activeItem: '',
    };

    constructor(props) {
        super(props);

        this.doLogout = this.doLogout.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentDidMount() {
        this.element.addEventListener('transitionend', () => {
            if (this.props.sidebarOpened) {
                this.element.classList.add(s.sidebarOpen);
            }
        }, false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sidebarOpened !== this.props.sidebarOpened) {
            if (nextProps.sidebarOpened) {
                this.element.style.height = `${this.element.scrollHeight}px`;
            } else {
                this.element.classList.remove(s.sidebarOpen);
                setTimeout(() => {
                    this.element.style.height = '';
                }, 0);
            }
        }
    }

    dismissAlert(id) {
        this.props.dispatch(dismissAlert(id));
    }

    doLogout() {
        this.props.dispatch(logoutUser());
    }

    toggleSidebar() {
        this.props.sidebarOpened
            ? this.props.dispatch(closeSidebar())
            : this.props.dispatch(openSidebar());
    }

    render() {
        return (
            <nav
                className={cx(s.root)}
                onClick={this.toggleSidebar}
                ref={(nav) => {
                    this.element = nav;
                }}
            >
                <header className={s.logo}>
                    <span className="font-italic">Fast</span><span className="fw-bold">RTK</span>
                </header>
                <ul className={s.nav}>
                    <LinksGroup
                        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
                        activeItem={this.props.activeItem}
                        header="Live Dashboard"
                        isHeader
                        iconName={<HomeIcon className={s.menuIcon} />}
                        link="/app/main"
                        index="main"
                    />
                    {/* <h5 className={[s.navTitle, s.groupTitle].join(' ')}>Configure</h5> */}
                    {/* <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="Devices"
                        isHeader
                        iconName={<TablesIcon className={s.menuIcon} />}
                        link="/app/devices"
                        index="devices"
                    /> */}
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="Users"
                        isHeader
                        iconName={<i className="fa fa-users fa-lg" />}
                        link="/app/users"
                        index="users"
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="Network"
                        isHeader
                        iconName={<i className="fa fa-signal fa-lg" />}
                        link="/app/network"
                        index="network"
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="Data"
                        isHeader
                        iconName={<i className="glyphicon glyphicon-file fa-lg" />}
                        link="/app/data"
                        index="data"
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="RTK"
                        isHeader
                        iconName={<BaseIcon className={s.menuIcon} />}
                        link="/app/rtk"
                        index="rtk"
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="About"
                        isHeader
                        iconName={<i className="test fa fa-info-circle fa-lg" />}
                        link="/app/about"
                        index="about"
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={t => this.props.dispatch(changeActiveSidebarItem(t))}
                        activeItem={this.props.activeItem}
                        header="Support"
                        isHeader
                        iconName={<i className="test fa fa-briefcase fa-lg" />}
                        link="/app/support"
                        index="support"
                    />
                </ul>
            </nav>
        );
    }
}

// function mapStateToProps(store) {
//     return {
//         sidebarOpened: store.navigation.sidebarOpened,
//         sidebarStatic: store.navigation.sidebarStatic,
//         alertsList: store.alerts.alertsList,
//         activeItem: store.navigation.activeItem,
//     };
// }

// export default connect(mapStateToProps)(Sidebar);
export default Sidebar;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import ReceiverService from '../receiverService';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { current } from '@reduxjs/toolkit';


const receiverservice = new ReceiverService;

export default function GnssCoordinateCreate(props) {
  let navigate = useNavigate();
  const [currentCoord, setCurrentCoord] = useState(null);

  useEffect(() => {
    if (props.currentcoord) {
      setCurrentCoord(props.currentcoord);
    }
  }, [props]);

  const [latitude, setLatitude] = useState("");
  const [latFormat, setLatFormat] = useState("");
  const [latHemi, setLatHemi] = useState("N");
  const [latitudeValid, setLatitudeValid] = useState(true);

  const [longitude, setLongitude] = useState("");
  const [lonFormat, setLonFormat] = useState("");
  const [longitudeValid, setLongitudeValid] = useState(true);
  const [lonHemi, setLonHemi] = useState("W");

  // Altitude
  const [altitude, setAltitude] = useState("");
  const [altitudeValid, setAltitudeValid] = useState(true);
  const [units, setUnits] = useState("m");

  // Datum
  const [datum, setDatum] = useState("NAD 1983 (2011)");

  // Note
  const [note, setNote] = useState("");
  const [noteValid, setNoteValid] = useState(true);

  const handleLatitudeChange = (e) => {
    if ((e.target.value).includes("N") || (e.target.value).includes("n") || Array.from(e.target.value)[0] === "+") {
      setLatHemi("N");
    }
    else if ((e.target.value).includes("S") || (e.target.value).includes("s") || Array.from(e.target.value)[0] === "-") {
      setLatHemi("S");
    }
    setLatitude(cleanCoordinate(e.target.value));
    setLatitudeValid(validateLatitude(cleanCoordinate(e.target.value)));
  };

  const handleLongitudeChange = (e) => {
    if ((e.target.value).includes("W") || (e.target.value).includes("w") || Array.from(e.target.value)[0] === "-") {
      setLonHemi("W");
    }
    else if ((e.target.value).includes("E") || (e.target.value).includes("e") || Array.from(e.target.value)[0] === "+") {
      setLonHemi("E");
    }
    setLongitude(cleanCoordinate(e.target.value));
    setLongitudeValid(validateLongitude(cleanCoordinate(e.target.value)));
  };

  const handleAltitudeChange = (e) => {
    if ((e.target.value).includes("ft") || (e.target.value).includes("FT") || (e.target.value).includes("f")) {
      setUnits("ft");
    }
    else if ((e.target.value).includes("meters") || (e.target.value).includes("m") || (e.target.value).includes("M")) {
      setUnits("m");
    }
    setAltitude(cleanAltitude(e.target.value));
    setAltitudeValid(validateAltitude(cleanAltitude(e.target.value)));
  };

  const handleUnitsChange = (event) => {
    setUnits(event.target.value);
  };

  const handleDatumChange = (event) => {
    setDatum(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  // Utilities
  const cleanCoordinate = (value) => {
    // Replace dashes with spaces. 45-12-23.12345 to 45 12 23.12345.
    let a = value.replace(/[-,](?=.)/g, ' ');
    // Replace non-numeric characters
    let b = a.replace(/[^0-9.\s]/g, '');
    // Replace multi-whitespace with single space
    let c = b.replace(/[ ]{2,}/g, ' ');
    return c;
  }

  const validateLatitude = (value) => {
    if (/^[-]?([1-9]|[12345678][0-9]|90).[0-9]+$/.test(value)) {
      setLatFormat("dd");
      return true;
    }
    else if (/^[0-9]?[0-9]\s+[0-9]?[0-9]\s+[0-9]?[0-9].[0-9]+$/.test(value)) {
      setLatFormat("dms");
      return true;
    }
    else {
      setLatFormat("!");
      return false;
    }
  }

  const validateLongitude = (value) => {
    if (/^[-]?([1-9]|[1-9][0-9]|1[0-7][0-9]|180).[0-9]+$/.test(value)) {
      setLonFormat("dd");
      return true;
    }
    else if (/^[0-9]?[0-9]?[0-9]\s+[0-9]?[0-9]\s+[0-9]?[0-9].[0-9]+$/.test(value)) {
      setLonFormat("dms");
      return true;
    }
    else {
      setLonFormat("!");
      return false;
    }
  }

  const cleanAltitude = (value) => {
    let cleaned = value.replace(/[^0-9.-]/g, '');
    return cleaned;
  }

  const validateAltitude = (value) => {
    if (!/^[-]?([0-9]?[0-9]?[0-9]?[0-9].[0-9]+)$/.test(value)) {
      return false;
    }
    else if (value === "") {
      return false;
    }
    else {
      return true;
    }
  }

  const feet2meters = (value) => {
    let feetNum = Number(value);
    let meterNum = feetNum * 0.3048;
    return meterNum;
  }

  function round(
    value,
    minimumFractionDigits,
    maximumFractionDigits
  ) {
    const formattedValue = value.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits,
      maximumFractionDigits
    })
    return Number(formattedValue)
  }

  // Decimal degrees = Degrees + (Minutes/60) + (Seconds/3600)
  const dms2dd = (value) => {
    let array = value.split(" ");
    let d = Number(array[0]);
    let m = Number(array[1]);
    let s = Number(array[2]);
    let dd = d + (m / 60) + (s / 3600);
    return round(dd, 8);
  }

  const LatFinal = () => {
    if (latFormat === "dms") {
      return Number(addTrailingZeros(dms2dd(latitude)))
    }
    else {
      return Number(addTrailingZeros(latitude))
    }
  }

  const FinalLatitude = () => {
    if (!latitudeValid) {
      var value = Number(0.00000000);
    }
    else if (latFormat === "dms") {
      var value = Number(dms2dd(latitude));
    }
    else {
      var value = Number(latitude);
    }
    if (latHemi === "N") {
      var value = value;
    }
    else if (latHemi === "S") {
      var value = -Math.abs(value);
    }

    let rounded = round(value, 8);
    return addTrailingZeros(rounded, 8);
  }

  const FinalLongitude = () => {
    if (!longitudeValid) {
      var value = Number(0.00000000);
    }
    else if (lonFormat === "dms") {
      var value = Number(dms2dd(longitude));
    }
    else {
      var value = Number(longitude);
    }
    if (lonHemi === "E") {
      var value = value;
    }
    else if (lonHemi === "W") {
      var value = -Math.abs(value);
    }

    let rounded = round(value, 8);
    return addTrailingZeros(rounded, 8);
  }

  const FinalAltitude = () => {
    if (!altitudeValid) {
      var value = Number(0.0);
    }
    else if (units === "ft") {
      var value = Number(feet2meters(altitude));
    }
    else {
      var value = Number(altitude);
    }
    let rounded = round(value, 3);
    return addTrailingZeros(rounded, 3);
  }

  function CoordinateDelta() {
    let lat1 = currentCoord.latitude;
    let lat2 = FinalLatitude();
    let lon1 = currentCoord.longitude;
    let lon2 = FinalLongitude();

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d * 1000; // Distance in meters
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  function addTrailingZeros(num, decimals) {
    return num.toFixed(decimals);
  }

  function formValid() {
    if (!latitudeValid) return false;
    else if (!longitudeValid) return false;
    else if (!altitudeValid) return false;
    else return true;
  }

  const SubmitButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    function submit() {
      receiverservice.setCoordinate(
        {
          latitude: FinalLatitude(),
          longitude: FinalLongitude(),
          altitude: FinalAltitude(),
          datum: datum,
          note: note
        }).then((response) => {
          setSubmitted(true);
          setLoading(false);
          props.refresh();
        })
        .catch((error) => {
          console.log(error.response.data.mountpoint[0]);
          setSubmitted(false);
          setLoading(false);
          setMessage(error.response.data.mountpoint[0]);
        });
    };

    if (loading) {
      return <CircularProgress size="1rem" color="inherit" />;
    }
    else if (submitted) {
      return (<Button variant="contained" onClick={navigate("/gnss/coordinate")} > Done</ Button>);
    }
    else {
      return (
        <React.Fragment>
          {message && <Alert severity="error">{message}</Alert>}
          <Button variant="contained" onClick={submit} disabled={!formValid()}>Save</Button>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ maxWidth: "75em" }}>
        <Grid item xs={10}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Set New Reference Coordinate
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth>
            <InputLabel id="datum-select">Datum</InputLabel>
            <Select
              labelId="datum-select"
              id="datum-select"
              value={datum}
              label="Datum"
              onChange={handleDatumChange}
            >
              <MenuItem value={"NAD 1983 (2011)"}>NAD 1983 (2011)</MenuItem>
              <MenuItem value={"NAD 1983 (PA11)"}>NAD 1983 (PA11)</MenuItem>
              <MenuItem value={"NAD 1983 (CSRS96)"}>NAD 1983 (CSRS96)</MenuItem>
              <MenuItem value={"NAD 1983 (CSRS)"}>NAD 1983 (CSRS)</MenuItem>
              <MenuItem value={"ITRF 2000"}>ITRF 2000</MenuItem>
              <MenuItem value={"ITRF 2005"}>ITRF 2005</MenuItem>
              <MenuItem value={"ITRF 2008"}>ITRF 2008</MenuItem>
              <MenuItem value={"ITRF 2014"}>ITRF 2014</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="latitude"
            label="Latitude"
            onChange={handleLatitudeChange}
            value={latitude}
            error={!latitudeValid}
            helperText={!latitudeValid || latitude.length === 0 ? "Latitude format incorrect." : ((lonFormat === "dms" ? "DMS format entered. Using DD: " : "Using: ") + FinalLatitude())}
            InputProps={{
              endAdornment: <InputAdornment position="end">{latHemi} {latFormat}</InputAdornment>,
            }}
            onPaste={() => { setLatitude(""); setLatFormat(""); setLatHemi("N") }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="longitude"
            label="Longitude"
            onChange={handleLongitudeChange}
            value={longitude}
            error={!longitudeValid}
            helperText={!longitudeValid || longitude.length === 0 ? "Longitude format incorrect." : ((lonFormat === "dms" ? "DMS format entered. Using DD: " : "Using: ") + FinalLongitude())}
            InputProps={{
              endAdornment: <InputAdornment position="end">{lonHemi} {lonFormat}</InputAdornment>,
            }}
            onPaste={() => { setLongitude(""); setLonFormat(""); setLonHemi("W") }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="altitude"
            label="Altitude"
            onChange={handleAltitudeChange}
            value={altitude}
            error={!altitudeValid}
            helperText={!altitudeValid ? "Altitude format incorrect." : "Enter a valid altitude value."}
            InputProps={{
              endAdornment: <InputAdornment position="end">Ellipsoidal Ht</InputAdornment>,
            }}
            onPaste={() => { setAltitude(""); setUnits(""); }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="unit-select">Units</InputLabel>
            <Select
              labelId="unit-select"
              id="unit-select"
              value={units}
              label="Units"
              onChange={handleUnitsChange}
            >
              <MenuItem value={"ft"}>Feet</MenuItem>
              <MenuItem value={"m"}>Meters</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            id="note"
            label="Note"
            multiline
            maxRows={4}
            onChange={handleNoteChange}
            value={note}
            error={!noteValid}
            helperText={!noteValid ? "Invalid note." : "Enter a note about this coordinate."}
          />
        </Grid>
        <Grid item xs={10}>
          <span>Latitude: {FinalLatitude()}</span>
          <br />
          <span>Longitude: {FinalLongitude()}</span>
          <br />
          <span>Ellipsoid Height: {FinalAltitude()} meters</span>
          <br />
          {
            currentCoord &&
            currentCoord.latitude &&
            currentCoord.longitude &&
            Number(FinalLatitude()) !== 0 &&
            Number(FinalLongitude()) !== 0 && (
              <React.Fragment>
                <br />
                <span>Distance from Current Coordiante:</span>
                <br />
                <span>H: {round(CoordinateDelta(), 5)} meters</span>
                <br />
                <span>V: {round((altitude - currentCoord.altitude), 3)} meters</span>
              </React.Fragment>
            )}
        </Grid>
        <Grid item xs={10}>
          <SubmitButton refresh={() => props.refresh()} />
        </Grid>
      </Grid>
    </React.Fragment >)

}

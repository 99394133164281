import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class AboutService {

    constructor() { }


    getVersions() {
        const url = `${API_URL}/api/base/versions/`;
        return axiosInstance.get(url).then(response => response.data);
    }
}

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { toast } from "react-toastify";
import BaseService from './baseService';

const baseService = new BaseService();

class BroadcastId extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentBroadcastId: '',
            newBroadcastId: '',
            validationMsg: 'Enter a broadcast ID # in the range 0-4095.',
            Warning: false,
        }

        this.validateInput = this.validateInput.bind(this);
        this.setBroadcastId = this.setBroadcastId.bind(this);
        this.getBroadcastId = this.getBroadcastId.bind(this);

    }

    componentDidMount() {
        this.getBroadcastId();
    }

    getBroadcastId() {
        this.props.loading("Getting broadcast ID...");
        baseService.getBroadcastId()
            .then((data) => {
                this.setState({
                    currentBroadcastId: data.broadcastid,
                });
                this.props.loading();
            });
        return true;
    }

    setBroadcastId() {
        this.props.loading("Setting broadcast ID to " + this.state.newBroadcastId + "...");
        baseService.setBroadcastId(this.state.newBroadcastId).then((data) => {
            this.setState({
                currentBroadcastId: data.broadcastid,
                newBroadcastId: ""
            });
            this.props.loading();
            toast.success("Broadcast ID updated");
        });
        return true;
    }

    validateInput(e) {
        if (e.target.value > 4095 || e.target.value < 0) {
            this.setState({ validationMsg: "Enter a broadcast ID # in the range 0-4095." });
            this.setState({ newBroadcastId: e.target.value });
            this.setState({ Warning: 'yellow' })
            this.setState({ inputValid: false })
            return false
        }
        else if (!e.target.value) {
            this.setState({ validationMsg: "Enter a broadcast ID # in the range 0-4095." });
            this.setState({ newBroadcastId: e.target.value });
            this.setState({ Warning: 'white' })
            this.setState({ inputValid: false })
            return false
        }
        else if (!e.target.value.match(/^[0-9]+$/)) {
            this.setState({ validationMsg: "Broadcast ID cannot contain characters. Only numbers." });
            this.setState({ newBroadcastId: e.target.value });
            this.setState({ Warning: 'yellow' })
            this.setState({ inputValid: false })
            return false
        }
        else {
            this.setState({ newBroadcastId: e.target.value });
            this.setState({ validationMsg: "Broadcast ID OK." });
            this.setState({ Warning: 'green' })
            this.setState({ inputValid: true })
            return true
        }
    }

    render() {

        return (
            <div>
                <form>
                    <h2>Broadcast ID: {this.state.currentBroadcastId}</h2>
                    {this.state.recording ? <p>Can't update while recording data</p> : <br></br>}
                    <h5>Set Broadcast ID</h5>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <span style={{ color: this.state.Warning }}>{this.state.validationMsg}</span><br />
                            <input className="form-control col-sm-4" style={{ marginTop: "5px", marginBottom: "5px" }} type="text" id="broadcastid" ref='broadcastid' value={this.state.newBroadcastId} onChange={this.validateInput} disabled={this.state.recording} />
                            <input className="btn btn-primary" type="submit" value="Set Broadcast ID" onClick={this.setBroadcastId} disabled={!this.state.inputValid} />
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default BroadcastId;
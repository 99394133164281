import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BaseService from './baseService';
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SupportService from '../support/supportService';

const baseService = new BaseService();
const supportService = new SupportService();

class StationName extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStationName: '',
            newStationName: '',
            validationMsg: 'Enter a name no more than 100 characters.',
            Warning: false,
            inputValid: false,
            modal: false
        }

        this.getStationName = this.getStationName.bind(this);
        this.setStationName = this.setStationName.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.reboot = this.reboot.bind(this);
    }

    modalToggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount() {
        this.getStationName();
    }

    getStationName() {
        // Get current station name
        baseService.getStationName().then((response) => this.setState({ currentStationName: response.name }));
        return true
    }

    setStationName() {
        // Set new station name 100 max chars
        this.props.loading("Changing name of local mountpoint to " + this.state.newStationName);
        this.modalToggle();
        baseService.setStationName(this.state.newStationName).then((response) => {
            this.setState({
                currentStationName: response.name,
                newStationName: '',
                validationMsg: 'Enter a name no more than 100 characters.',
                Warning: false,
                inputValid: false
            });
            this.props.loading();
            toast.success("Station name changed to " + response.name);
        });
        return true;
    }

    validateInput(e) {
        let specialChars = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (e.target.value.length > 100) {
            this.setState({
                validationMsg: "Station name must be less than 100 characters.",
                newStationName: e.target.value,
                Warning: 'yellow',
                inputValid: false,
            });
            return false
        }
        else if (specialChars.test(e.target.value)) {
            this.setState({
                validationMsg: "Station name cannot contain special characters.",
                newStationName: e.target.value,
                Warning: 'white',
                inputValid: false,
            });
            return false
        }
        else if (!e.target.value) {
            this.setState({
                validationMsg: "Enter a name no more than 100 characters.",
                newStationName: e.target.value,
                Warning: 'white',
                inputValid: false,
            });
            return false
        }
        else {
            this.setState({
                newStationName: e.target.value,
                validationMsg: "Staion name OK.",
                Warning: 'green',
                inputValid: true,
            });
            return true
        }
    }

    reboot() {
        this.modalToggle();
        this.props.loading("Rebooting...Refresh this page in a minute.");
        supportService.restart();
    }

    render() {

        return (
            <div>
                <form onSubmit={this.handleUpdateStationName}>
                    <h2>Station Name: {this.state.currentStationName}</h2>
                    {this.state.recording ? <p>Can't update while recording data</p> : <br></br>}
                    <br />
                    <h5>Change station name</h5>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <span style={{ color: this.state.Warning }}>{this.state.validationMsg}</span><br />
                            <input className="form-control col-sm-4" style={{ marginTop: "5px", marginBottom: "5px" }} type="text" id="stationname" ref='stationname' value={this.state.newStationName} onChange={this.validateInput} />
                            <input className="btn btn-primary" type="submit" value="Update Station Name" onClick={this.setStationName} disabled={!this.state.inputValid} />
                        </div>
                    </div>
                </form>
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className={this.props.className}>
                    <ModalHeader toggle={this.modalToggle}>Reboot now?</ModalHeader>
                    <ModalBody>
                        Reboot required to use <strong>{this.state.currentStationName}</strong> as the new station name. Reboot now?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={this.reboot}>Reboot now</Button>{' '}
                        <Button color='danger' onClick={this.modalToggle}>Reboot later</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default StationName;

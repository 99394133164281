import React, { Component } from 'react';
import ReceiverService from './receiver/receiverService';
import s from './support.module.scss';
import SupportService from './supportService'
import Loading from '../../components/Loading/Loading';
import { toast } from "react-toastify";

const supportService = new SupportService();
const receiverService = new ReceiverService();

class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {
            command: '',
            result: '',
            loading: false,
        }
        this.handleSendCommand = this.handleSendCommand.bind(this);
        this.restartDevice = this.restartDevice.bind(this);
        this.convertToRover = this.convertToRover.bind(this);
        this.convertToBase = this.convertToBase.bind(this);
        this.loading = this.loading.bind(this);

    }

    onChange(e) {
        this.setState({ command: e.target.value })
    }

    loading(msg) {
        if (msg) {
            this.setState({
                loading: true,
                loadingmsg: msg,
            });
        }
        else {
            this.setState({
                loading: false,
                loadingmsg: null,
            });
        }
    }

    restartDevice() {
        supportService.restart();
    }

    handleSendCommand() {
        receiverService.sendCommand(this.state.command).then((data) =>
            this.setState({
                command: '',
                result: this.state.command + " sent with " + data.status
            })
        );
    }

    convertToRover() {
        this.loading("Converting base receiver to rover...");
        receiverService.convertToRover().then((data) => {
            this.loading();
            if (data.status) {
                toast.success("Successfully converted base to rover.");
                toast.info("You can now remove the receiver from the base.");
            }
            else {
                toast.error("Failed to convert base to rover. Try again or convert manually.");
            }
        })
    }

    convertToBase() {
        receiverService.convertToBase();
    }

    render() {
        var self = this;
        return (
            <div>
                {this.state.loading ? <Loading message={this.state.loadingmsg} /> : null}
                <h1 className="page-title"><span className="fw-semi-bold">Support</span></h1>
                <br></br>
                <br></br>
                <form onSubmit={this.handleSendCommand}>
                    <h4>Send Command </h4>
                    <div className="form-group row">
                        <div className="col-sm-10">
                            <input className="form-control col-sm10" id="sendCommand" type="text" ref='command' value={this.state.command} onChange={e => this.onChange(e)} />
                        </div>
                    </div>
                    <input className="btn btn-primary" type="submit" value="Submit" />
                </form>
                <br></br>
                <p>{this.state.result}</p>
                <br></br>
                <button type="submit" className="btn btn-default my-2" onClick={this.convertToRover}>Convert To Rover</button>
                <button type="submit" className="btn btn-default ml-2 my-2" onClick={this.convertToBase}>Convert To Base</button>
                <button type="submit" className="btn btn-default ml-2 my-2" onClick={this.restartDevice}>Restart Device</button>
            </div>
        )
    }

}

export default Support;


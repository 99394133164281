import React, { Component } from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Loading from '../../components/Loading/Loading';
import { toast } from "react-toastify";

import classnames from 'classnames';
import BaseService from './baseService';
import StationName from './StationName';
import BroadcastId from './BroadcastId';
import ReferenceCoordinate from './ReferenceCoordinate';

const baseService = new BaseService();

class RTKBase extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: '1',
            loading: false,
            loadingmsg: null,
        }

        this.toggle = this.toggle.bind(this);
        this.loading = this.loading.bind(this);
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    loading(msg) {
        if (msg) {
            this.setState({
                loading: true,
                loadingmsg: msg,
            });
        }
        else {
            this.setState({
                loading: false,
                loadingmsg: null,
            });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        var self = this;
        return (
            <div>
                {this.state.loading ? <Loading message={this.state.loadingmsg} /> : null}
                <React.Fragment>
                    <h1 className="page-title"><span className="fw-semi-bold">RTK Streaming</span></h1>

                    {/* tabs */}
                    <Nav className="bg-transparent" tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <span>Stations</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <span className="mr-xs">Reference Coordinate</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                <span className="mr-xs">Station Name</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}
                            >
                                <span className="mr-xs">Broadcast ID</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                        <TabPane tabId="1">
                            <span></span>
                        </TabPane>

                        <TabPane tabId="2">
                            <ReferenceCoordinate loading={this.loading} ></ReferenceCoordinate>
                        </TabPane>

                        <TabPane tabId="3">
                            <StationName loading={this.loading} ></StationName>
                        </TabPane>

                        <TabPane tabId="4">
                            <BroadcastId loading={this.loading} ></BroadcastId>
                        </TabPane>


                    </TabContent>
                </React.Fragment>
            </div>
        )
    }

}

export default RTKBase;


import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PeopleIcon from '@mui/icons-material/People';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import RelayService from './relayService';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import RelayCreate from './relayCreate';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';

const relayservice = new RelayService();

const RelayValidateButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [showToolTip, setShowToolTip] = useState(false);

  const validaterelay = () => {
    setLoading(true);
    relayservice.validateRelay(props.id).then((response) => {
      if (response.result === true) {
        setValidated(true);
      }
      else {
        setMessage(response.message);
        setShowToolTip(true);
        setTimeout(
          function () {
            setShowToolTip(false);
          }, 5000);
      }
      setChecked(true);
      setLoading(false);
    });
  }

  if (loading) { return (<Button variant="outlined" disabled><CircularProgress size="1rem" color="inherit" /></Button>); }
  else if (!loading && checked && validated) { return (<Button variant="outlined"><CheckIcon color="success" /></Button>); }
  else if (!loading && checked && !validated) { return (<Button variant="outlined"><Tooltip title={message} open={showToolTip}><ErrorOutlineIcon color="error" /></Tooltip></Button>); }
  else { return (<Button variant="contained" onClick={validaterelay} disabled={loading}>Test</Button>); }
}


const RelayRow = (props) => {
  const navigate = useNavigate();
  const [relay, setRelay] = useState([]);
  const [activating, setActivating] = useState(false);

  useEffect(() => {
    setRelay(props.relay);
  }, []);

  function toggle() {
    setActivating(true);
    if (relay.active) {
      relayservice.disableRelay(relay.id).then((response) => {
        if (response.result) {
          setRelay(prevState => ({
            ...prevState,
            active: false
          }));
          setActivating(false);
        }
      })
        .catch((error) => {
          setActivating(false);
        });
    }
    else {
      relayservice.enableRelay(relay.id).then((response) => {
        if (response.result) {
          setRelay(prevState => ({
            ...prevState,
            active: true
          }));
          setActivating(false);
        }
      })
        .catch((error) => {
          setActivating(false);
        });
    }
  }

  return (
    <TableRow
      key={relay.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Chip label={relay.name} color="primary" />
      </TableCell>
      <TableCell component="th" scope="row">
        <Chip label={relay.relay_mountpoint} color="info" variant="outlined" />
      </TableCell>
      <TableCell align="left">{relay.server}</TableCell>
      <TableCell align="left">{activating ? <CircularProgress size="1rem" color="inherit" /> : <Switch checked={relay.active} onClick={toggle} color="success" />}</TableCell>
      <TableCell align="left">
      <Tooltip title="Edit">
        <IconButton onClick={() => navigate("/caster/relays/" + relay.id)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
    </TableRow>
  );
}

export default function RelayList(props) {

  const [age, setAge] = useState(10);
  const [loading, setLoading] = useState(true);
  const prevAge = useRef(10);
  const [relays, setRelays] = useState([]);
  const [showCreateRelayForm, setShowCreateRelayForm] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    setLoading(true);
    relayservice.getRelays().then((response) => {
      setRelays(response);
      setShowCreateRelayForm(false);
      setLoading(false);
    });
  };

  if (relays.length === 0) {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">NTRIP Relays</span></h1>
        <Stack direction="column" alignItems="center">
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
            <h3>Add Relay</h3>
            <p>Relays allow this base to network multiple RTK stations together by connecting to other NTRIP casters over the internet as a client and serve their mountpoints as it's own. </p>
            <Stack spacing={2} direction="column" alignItems="center">
              <p>A valid login is required on the remote RTK station to relay it's mountpoints.</p>
              <p>This station must be able to communicate with the caster via the internet or local network.</p>
              <p>Use the form below to add a new relay station and verify connectivity.</p>
              <RelayCreate refresh={() => refresh()} />
            </Stack>
          </Paper>
        </Stack>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">NTRIP Relays</span></h1>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Local Mount</TableCell>
              <TableCell>Relay Mount</TableCell>
              <TableCell align="left">Server</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && relays.map((relay, index) => <RelayRow key={relay.id} relay={relay} />)}
          </TableBody>
        </Table>
        {loading && <LinearProgress speed={100} />}
        {!relays.length && !showCreateRelayForm && (
          <Stack spacing={2} direction="row" alignItems="center">
            <Paper style={{ margin: "auto", marginTop: "2em", marginBottom: "2em", padding: "1em", width: "50%" }} >
              <Stack spacing={2} direction="column" alignItems="center">
                <p>No existing relays</p>
                <Button variant="contained" onClick={() => setShowCreateRelayForm(true)}>+ New Relay</Button>
              </Stack>
            </Paper>
          </Stack>
        )}
        {showCreateRelayForm &&
          <Stack direction="column" alignItems="center">
            <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
              <h3>Add Relay</h3>
              <p>Relays allow this base to network multiple RTK stations together by connecting to other NTRIP casters over the internet as a client and serve their mountpoints as it's own. </p>
              <Stack spacing={2} direction="column" alignItems="center">
                <p>A valid login is required on the remote RTK station to relay it's mountpoints.</p>
                <p>This station must be able to communicate with the caster via the internet or local network.</p>
                <p>Use the form below to add a new relay station and verify connectivity.</p>
                <RelayCreate refresh={() => refresh()} />
              </Stack>
            </Paper>
          </Stack>}
        {relays.length > 0 && !showCreateRelayForm && <Button variant="contained" onClick={() => setShowCreateRelayForm(true)}>+ New Relay</Button>}
      </React.Fragment >
    );
  }
}

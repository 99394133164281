import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { toast } from "react-toastify";

import { withRouter } from 'react-router';
import BaseService from './baseService';
import DataService from '../gnss_receiver/rinex/dataService';
import { Button } from 'reactstrap';


const baseService = new BaseService();
const dataService = new DataService();

class ReferenceCoordinate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            latitude: '',
            longitude: '',
            altitude: '',
            newLat: '',
            newLng: '',
            newAlt: '',
            recording: false,
            modal: false,
            isEditing: false,
        }


        this.modalToggle = this.modalToggle.bind(this);
        this.changeLatCoordinate = this.changeLatCoordinate.bind(this);
        this.changeLngCoordinate = this.changeLngCoordinate.bind(this);
        this.changeAltCoordinate = this.changeAltCoordinate.bind(this);
        this.isEditing = this.isEditing.bind(this);
        this.handleUpdateRefCoordinate = this.handleUpdateRefCoordinate.bind(this);
        this.handleSetCurrentToRefCoordinate = this.handleSetCurrentToRefCoordinate.bind(this);
        this.refreshCoordinate = this.refreshCoordinate.bind(this)
    }


    componentDidMount() {
        this.refreshCoordinate();
        this.isRecording();
    }

    componentWillUnmount() {

    }

    refreshCoordinate() {
        this.props.loading("Refreshing base coordinate...");
        baseService.getReferenceCoordinate().then(response => {
            this.setState({
                latitude: response.coordinate.latitude,
                longitude: response.coordinate.longitude,
                altitude: response.coordinate.altitude
            });
            this.props.loading();
        });
    }

    isRecording() {
        dataService.recordingStatus().then(data => {
            this.setState({
                recording: data.payload.recording
            })
        })
    }

    modalToggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    changeLatCoordinate(event) {
        this.setState({
            newLat: event.target.value
        })
    }

    changeLngCoordinate(event) {
        this.setState({
            newLng: event.target.value
        })
    }

    changeAltCoordinate(event) {
        this.setState({
            newAlt: event.target.value
        })
    }

    isEditing(event) {
        this.setState({
            isEditing: event.target.value
        })
    }

    handleUpdateRefCoordinate() {
        this.props.loading("Updating base coordinate...");
        const coordinate = { latitude: this.state.newLat, longitude: this.state.newLng, altitude: this.state.newAlt };
        baseService.updateReferenceCoordinate(coordinate).then(data => {
            this.setState({
                latitude: data.payload.rxinfo.coordinate.latitude,
                longitude: data.payload.rxinfo.coordinate.longitude,
                altitude: data.payload.rxinfo.coordinate.altitude
            });
            this.props.loading();
            toast.success("Base coordinate updated");
        });
    }

    handleSetCurrentToRefCoordinate() {
        this.modalToggle();
        this.props.loading("Setting base coordinate using current position...");
        baseService.setCurrentToRefCoordinate().then(data => {
            this.setState({
                latitude: data.payload.rxinfo.coordinate.latitude,
                longitude: data.payload.rxinfo.coordinate.longitude,
                altitude: data.payload.rxinfo.coordinate.altitude
            })
            this.props.loading();
            toast.success("Base coordinate updated");
        });
    }


    render() {
        return (
            <div>
                <div style={{ border: "3px solid white", padding: "10px", borderRadius: "10px" }}>

                    <table style={{ marginRight: "auto" }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}><span style={{ fontSize: "1.5rem" }}>Latitude </span> </td>
                                <td style={{ textAlign: "left" }}><span className="fw-semi-bold" style={{ fontSize: "1.5rem" }}>{this.state.latitude}</span></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><span style={{ fontSize: "1.5rem" }}>Longitude </span> </td>
                                <td style={{ textAlign: "left" }}><span className="fw-semi-bold" style={{ fontSize: "1.5rem" }}>{this.state.longitude}</span></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><span style={{ fontSize: "1.5rem" }}>Altitude </span> </td>
                                <td style={{ textAlign: "left" }}><span className="fw-semi-bold" style={{ fontSize: "1.5rem" }}>{this.state.altitude}</span><span> meters</span></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <br />
                <br />
                <form onSubmit={this.handleUpdateRefCoordinate}>
                    <h4>Set reference coordinate:</h4>
                    {this.state.recording ? <p>Can't update while recording data</p> : <br></br>}
                    <div className="form-group row">
                        <label htmlFor="latitude" className="col-sm-2 col-form-label">Latitude (dd.dddddddd)</label>
                        <div className="col-sm-10">
                            <input className="form-control col-sm-4" type="text" id="latitude" ref='latitude' onChange={this.changeLatCoordinate} disabled={this.state.recording} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="longitude" className="col-sm-2 col-form-label">Longitude (+ east/- west ddd.dddddddd)</label>
                        <div className="col-sm-10">
                            <input className="form-control col-sm-4" type="text" id="longitude" ref='longitude' onChange={this.changeLngCoordinate} disabled={this.state.recording} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="altitude" className="col-sm-2 col-form-label">Ellipsoid Height (HAE meters xxxx.xx)</label>
                        <div className="col-sm-10">
                            <input className="form-control col-sm-4" type="text" id="altitude" ref='altitude' onChange={this.changeAltCoordinate} disabled={this.state.recording} />
                        </div>
                    </div>
                    <input className="btn btn-primary" type="submit" value="Submit Coordinates" />
                </form>
                <input className="btn btn-primary mt-4" type="submit" value="Set To Current Approximate Position" onClick={this.modalToggle} />
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className={this.props.className}>
                    <ModalHeader toggle={this.modalToggle}>Update Reference Coordinates</ModalHeader>
                    <ModalBody>
                        Are you sure you want to set the reference position to the current <strong>approximate</strong> position instead?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={this.handleSetCurrentToRefCoordinate}>Confirm</Button>{' '}
                        <Button color='danger' onClick={this.modalToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }

}

export default ReferenceCoordinate;


import axiosInstance from '../../../util/axiosAPI';
import { API_URL } from '../../../config';

export default class ReceiverService {

    constructor() { }


    receiverConfig() {
        const url = `${API_URL}/api/receiver/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    convertToRover() {
        const url = `${API_URL}/api/receiver/mode/rover/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    convertToBase() {
        const url = `${API_URL}/api/receiver/mode/base/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    sendCommand(command) {
        const url = `${API_URL}/api/receiver/command/`;
        return axiosInstance.post(url, { command: command }).then(response => response.data);
    }

    broadcastRadio() {
        const url = `${API_URL}/api/receiver/broadcast/`;
        return axiosInstance.post(url, { broadcast_mode: "radio" }).then(response => response.data);
    }

    broadcastNtrip() {
        const url = `${API_URL}/api/receiver/broadcast/`;
        return axiosInstance.post(url, { broadcast_mode: "ntrip" }).then(response => response.data);
    }
}
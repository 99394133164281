import axiosInstance from '../../util/axiosAPI';
import { API_URL } from "../../config";

export default class BaseService {

    constructor() { }


    getStats() {
        const url = `${API_URL}/api/base/stats/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    getReceiverInfo() {
        const url = `${API_URL}/api/receiver/info/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    updateReferenceCoordinate(coordinate) {
        const url = `${API_URL}/api/base/coordinate/`;
        return axiosInstance.post(url, { type: "set", latitude: coordinate.latitude, longitude: coordinate.longitude, altitude: coordinate.altitude }).then(response => response.data);
    }

    getReferenceCoordinate() {
        const url = `${API_URL}/api/base/coordinate/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setCurrentToRefCoordinate() {
        const url = `${API_URL}/api/base/coordinate/`;
        return axiosInstance.post(url, { type: "approx" }).then(response => response.data);
    }


    getStationName() {
        const url = `${API_URL}/api/base/stationname/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setStationName(newStationName) {
        const url = `${API_URL}/api/base/stationname/`;
        const data = { "stationName": newStationName }
        return axiosInstance.post(url, data).then(response => response.data);
    }

    getBroadcastId() {
        const url = `${API_URL}/api/base/broadcastid/`;
        return axiosInstance.get(url).then(response => response.data);
    }

    setBroadcastId(newId) {
        const url = `${API_URL}/api/base/broadcastid/`;
        const data = { "broadcastId": newId }
        return axiosInstance.post(url, data).then(response => response.data);
    }
}
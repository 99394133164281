import React, { Component } from 'react';
import ReceiverService from './receiverService';

const receiverService = new ReceiverService();

class Receiver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            antennaType: '',
            correctionProtocol: '',
            constellation: '',
            rtcmMessages: ''
        }
    }

    componentDidMount() {
        receiverService.receiverConfig().then(data => {
            this.setState({
                antennaType: data.antennaType,
                correctionProtocol: data.correctionProtocol,
                constellation: data.constellation,
                rtcmMessages: data.rtcmMessages
            })
        });
    }

    render() {
        return (
            <div>
                <h3>Arrow Receiver</h3>
                <p>Antenna Type: {this.state.antennaType}</p>
                <p>Correction Protocol: {this.state.correctionProtocol}</p>
                <p>Constellation Output: {this.state.constellation}</p>
                <p>RTCM3 Messages: {this.state.rtcmMessages}</p>
            </div>
        )
    }

}

export default Receiver;
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import AboutService from './aboutService';
import Receiver from '../support/receiver/receiver';

const aboutService = new AboutService();

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receiverSerial: '',
            receiverFirmware: '',
            receiverOptions: '',
            ntripVersion: '',
            managerVersion: ''
        }
    }

    componentDidMount() {
        aboutService.getVersions().then(data => {
            this.setState({
                receiverSerial: data.receiverSerial,
                receiverFirmware: data.receiverFirmware,
                receiverOptions: data.receiverOptions,
                ntripVersion: data.ntripVersion,
                managerVersion: data.managerVersion
            })
        }).catch(error => { console.log("ERROR: ", error) });
    }

    render() {
        return (
            <div>
                <h1 className="page-title"><span className="fw-semi-bold">About</span></h1>
                <p>FastRTK Software Version: 5.0.0</p>
                <p>Documentation: <a target="_blank" href="http://wiki.fastrtk.com/">http://wiki.fastrtk.com/</a></p>
                <p>Discovery Management Group LLC 2023</p>
            </div>
        )
    }

}

export default About;


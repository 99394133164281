import axios from "axios";
import { API_URL } from "../config";
import authHeader from "../auth/auth-header";
import store from '../redux/store/store';
import { login, logout } from '../redux/store/auth.slice';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from "../actions/user";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  // headers: {
  //   'Authorization': authHeader(),
  //   'Content-Type': 'application/json',
  //   'accept': 'application/json'
  // }
});

const refreshAccessToken = () => {
  console.log("Refreshing JWT token.")
  let refreshtoken = localStorage.getItem('refresh')
  if (refreshtoken === null) {
    console.log("Refresh token is null");
    return null;
  }
  else {
    return axios.post(API_URL + '/api/token/refresh/', { "refresh": refreshtoken })
      .then((response) => {
        localStorage.setItem('access', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
        return response.data.access;
      })
      .catch((error) => {
        return null;
      });
  }
}

// // for multiple requests
// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach(prom => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   })

//   failedQueue = [];
// }

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = authHeader();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  console.log("API response with error!");
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let refresh_token = localStorage.getItem('refresh');
    if (refresh_token === null || refresh_token === undefined) {
      console.log("No refresh token available. Logout!");
      store.dispatch(logout());
    }
    else {
      const access_token = await refreshAccessToken();
      if (!access_token) {
        console.log("Could not refresh JWT token, logging out...");
        store.dispatch(logout());
      }
      axiosInstance.defaults.headers.common['Authorization'] = authHeader();
      originalRequest.headers['Authorization'] = 'JWT ' + access_token;
      return axiosInstance(originalRequest);
    }
  }
  return Promise.reject(error);
});


// axiosInstance.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {

//   const originalRequest = error.config;

//   if (error.response && error.response.status === 401 && !originalRequest._retry) {

//     if (isRefreshing) {
//       return new Promise(function (resolve, reject) {
//         failedQueue.push({ resolve, reject })
//       }).then(token => {
//         originalRequest.headers['Authorization'] = authHeader();
//         return axiosInstance(originalRequest);
//       }).catch(err => {
//         return Promise.reject(err);
//       })
//     }

//     originalRequest._retry = true;
//     isRefreshing = true;

//     return new Promise(function (resolve, reject) {
//       axiosInstance.post('/api/token/refresh/', { "refresh": JSON.parse(localStorage.getItem('user')).refresh })
//         .then(({ data }) => {
//           var user = JSON.parse(localStorage.getItem('user'));
//           user.access = data.access;
//           user.refresh = data.refresh;
//           localStorage.setItem('user', JSON.stringify(user));
//           axiosInstance.defaults.headers.common['Authorization'] = authHeader();
//           originalRequest.headers['Authorization'] = authHeader();
//           processQueue(null, data.access);
//           resolve(axiosInstance(originalRequest));
//         })
//         .catch((err) => {
//           processQueue(err, null);
//           reject(err);
//         })
//         .finally(() => { isRefreshing = false })
//     })
//   }

//   return Promise.reject(error);
// });

export default axiosInstance
import axiosInstance from '../../util/axiosAPI';
import { API_URL } from '../../config';

export default class SupportService {

    constructor() { }


    restart() {
        const url = `${API_URL}/api/support/restart/`;
        return axiosInstance.get(url).then(response => response.data);
    }
}

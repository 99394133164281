import * as React from 'react';
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import GnssCoordinateList from './list';
import GnssCoordinateCreate from './create';
import GnssCoordinateCurrent from './current';
import { LinearProgress } from '@mui/material';
import ReceiverService from '../receiverService';
import CoordinateMapView from './CoordinateMapView';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

const service = new ReceiverService;

export default function GnssCoordinate() {
  const [loading, setLoading] = useState(true);


  const [largescreen, setLargescreen] = React.useState({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addListener(setLargescreen);
    // this is the cleanup function to remove the listener
    return () => mediaQuery.removeListener(setLargescreen);
  }, []);

  const [coordinates, setCoordinates] = useState([]);
  const [center, setCenter] = useState([]);
  const [highlight, setHighlight] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
    service.getCoordinates().then(data => {
      setCoordinates(data);
      if (data.length > 0) {
      setCenter([data[0].latitude, data[0].longitude]);
      }
      setLoading(false);
    });
  }

  const SetApproxButton = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [result, setResult] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      setLoading(true);
      service.setCoordinate(
        {
          latitude: 0.0,
          longitude: 0.0,
          altitude: 0.0,
          datum: "Unknown",
          note: ""
        })
        .then((response) => {
          setLoading(false);
          setSubmitted(true);
          setResult(true);
          fetchData();
        }).catch((error) => {
          setLoading(false);
          setSubmitted(true);
        })
    };

    return (
      <React.Fragment>
        <Button variant="contained" onClick={submit} disabled={(loading || submitted)}>
          {!loading && !submitted && !result && <React.Fragment>Set to Current(Approx.)</React.Fragment>}
          {loading && (<CircularProgress size="1rem" color="inherit" />)}
          {!loading && submitted && result && <CheckIcon color="success" />}
          {!loading && submitted && !result && <React.Fragment><ErrorOutlineIcon color="error" /><span>Error: Refresh and try again."</span></React.Fragment>}
        </Button>
      </React.Fragment>
    );
  }


  function zoomTo(lat, lon) {
    setHighlight([lat, lon]);
    setCenter([lat, lon]);
  }

  function highlightCoord(lat, lon) {
    setHighlight([lat, lon]);
  }

  if (loading) {
    return <LinearProgress />;
  }
  else if (coordinates.length === 0) {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">GNSS Reference Coordinate</span></h1>
        <Stack spacing={2} direction="column">
          <SetApproxButton />
          <GnssCoordinateCreate
            refresh={() => fetchData()}
          />
        </Stack>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">GNSS Reference Coordinate</span></h1>
        <Stack spacing={2} direction="column">
          <CoordinateMapView coordinates={coordinates} center={center} highlight={highlight} zoom={10} />
          <Button
            variant="contained"
            onClick={() => setCenter([coordinates[0].latitude, coordinates[0].longitude])}
          >
            Zoom to Current
          </Button>
          <Button
            variant="contained"
            onClick={() => fetchData()}
          >
            Refresh
          </Button>
          <GnssCoordinateCurrent coordinate={coordinates[0]} />
          <SetApproxButton />
          {largescreen.matches && <GnssCoordinateList coords={coordinates} zoomTo={zoomTo} highlightCoord={highlightCoord} refresh={() => fetchData()} />}
          <GnssCoordinateCreate
            currentcoord={{
              latitude: coordinates[0].latitude,
              longitude: coordinates[0].longitude,
              altitude: coordinates[0].altitude
            }}
            refresh={() => fetchData()} />
        </Stack>
      </React.Fragment>
    );
  }
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReceiverService from '../receiverService';
import SourceService from '../../sources/sourceService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { validate } from 'uuid';
import { LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const receiverservice = new ReceiverService;
const sourceservice = new SourceService;

export default function GnssControllerSettings() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [localmounts, setLocalMounts] = useState([]);
  const [mountSelected, setMountSelected] = useState();
  const [showForm, setShowForm] = useState();

  const [device, setDevice] = useState("");
  const [deviceValid, setDeviceValid] = useState(true);

  const [baudrate, setBaudrate] = useState("");
  const [baudrateValid, setBaudrateValid] = useState(true);

  const [caster, setCaster] = useState("");
  const [casterValid, setCasterValid] = useState(true);

  const [port, setPort] = useState(10000);
  const [portValid, setPortValid] = useState(true);

  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const [mountpoint, setMountpoint] = useState("");
  const [mountpointValid, setMountpointValid] = useState(true);


  useEffect(() => {
    populateForm();
  }, []);

  const populateForm = () => {
    setLoading(true);
    sourceservice.getSources().then((response) => {
      let fetchedMounts = response;
      setLocalMounts(fetchedMounts);

      receiverservice.getControllerSettings().then((response) => {
        setLoading(false);
        if (response.casterip === "127.0.0.1") {
          let foundmount = fetchedMounts.find(element => element.name === response.mountpoint);
          setMountSelected(foundmount);
        }
        else {
          setMountSelected("Other");
          setShowForm(true);
        }
        setDevice(response.device);
        setCaster(response.casterip);
        setPort(response.casterport);
        setUsername(response.user);
        setPassword(response.password);
        setMountpoint(response.mountpoint);
      });
    });
  }


  const handleCasterChange = (e) => {
    setCaster(e.target.value);
    setCasterValid(validateCaster(e.target.value));
    resetCasterValidation();
  };

  const handlePortChange = (event) => {
    let newPort = cleanPort(event.target.value);
    setPort(newPort);
    setPortValid(validatePort(newPort));
    resetCasterValidation();
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    resetCasterValidation();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    resetCasterValidation();
  };

  const handleMountpointChange = (event) => {
    let newMountpoint = cleanMountpoint(event.target.value);
    setMountpointValid(validateMountpoint(newMountpoint));
    setMountpoint(newMountpoint);
  };

  function resetCasterValidation() {

  }

  const handleChangeMountpoint = (event) => {
    if (event.target.value === "Other") {
      setShowForm(true);
      setMountSelected(event.target.value);
      setCaster("");
      setPort("10000");
      setMountpoint("");
      setPassword("");
    }
    else {
      setShowForm(false);
      let mount = event.target.value;
      setMountSelected(mount);
      setCaster("127.0.0.1");
      setPort("10000");
      setMountpoint(mount.name);
      setPassword(mount.password);
    }
  };

  const SubmitButton = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");

    const submit = () => {
      setSubmitting(true);
      receiverservice.updateControllerSettings(
        {
          device: device,
          casterip: caster,
          casterport: port,
          user: username,
          password: password,
          mountpoint: mountpoint
        }).then((response) => {
          receiverservice.restartController();
          setSubmitted(true);
          setSubmitting(false);
          populateForm();
        });
    };
    return (
      <React.Fragment>
        <Button variant="contained" onClick={submit} disabled={(submitting || submitted)}>
          {!submitted && !submitting && "Save"}
          {submitting && (<CircularProgress size="1rem" color="inherit" />)}
          {!submitting && submitted && <CheckIcon color="success" />}
          {!submitting && submitted && <ErrorOutlineIcon color="error" />}
        </Button>
      </React.Fragment>
    );
  }

  if (loading) {
    return <LinearProgress />
  }
  else {
    return (
      <React.Fragment>
        <h1 className="page-title"><span className="fw-semi-bold">GNSS Connector</span></h1>
        <Stack fullWidth direction="column" alignItems="center">
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
            <h3>GNSS Connect Settings</h3>
            <Stack fullwidth spacing={2} direction="column" alignItems="center">
              <React.Fragment>
                <Typography>Connect the GNSS Receiver to this base station's NTRIP Caster or a remote caster.</Typography>
                <br />

                <Stack fullwidth spacing={2} direction="row" alignItems="center">
                  <FormControl fullwidth disabled={localmounts.length < 1}>
                    <InputLabel id="mountpoint">Mountpoints</InputLabel>
                    <Select
                      labelId="mountpoint"
                      id="mountpoint-select"
                      value={mountSelected}
                      label="Mount name"
                      onChange={handleChangeMountpoint}
                      autoFocus
                      fullwidth
                      style={{ minWidth: "15em" }}
                    >
                      {localmounts.map((mount, index) =>
                        <MenuItem fullWidth value={mount}>{mount.name}{mount.identifier && (" - " + mount.identifier)}</MenuItem>
                      )}
                      <MenuItem fullWidth value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>


                <Accordion expanded={showForm} onChange={() => setShowForm(!showForm)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="settings-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack fullwidth spacing={2} direction="column" alignItems="center">
                      <TextField
                        fullWidth
                        id="caster-ip"
                        label="Caster IP or Domain name"
                        onChange={handleCasterChange}
                        value={caster}
                        error={!casterValid}
                        helperText={!casterValid ? "Invalid caster address" : "Enter a valid dns name or IP."}
                        autoFocus
                      />
                      <TextField
                        fullWidth
                        id="caster-port"
                        label="Caster Port"
                        onChange={handlePortChange}
                        value={port}
                        error={!portValid}
                        helperText={!portValid ? "Port number must be 1-63535." : "Enter a port number 1-63535."}
                      />
                      {/* <TextField
                      fullWidth
                      id="username"
                      label="Username"
                      onChange={handleUsernameChange}
                      value={username}
                      error={!usernameValid}
                      helperText={!usernameValid ? "Check username." : "Enter a valid username."}
                    /> */}

                      <TextField
                        fullWidth
                        id="password"
                        label="Password"
                        onChange={handlePasswordChange}
                        value={password}
                        error={!passwordValid}
                        helperText={!passwordValid ? "Check password." : "Enter a valid password."}
                      />
                      <TextField
                        fullWidth
                        id="mountpoint"
                        label="Mountpoint"
                        onChange={handleMountpointChange}
                        value={mountpoint}
                        error={!mountpointValid}
                        helperText={!mountpointValid ? "Invalid mountpoint name." : "Enter a valid mountpoint name."}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
              <SubmitButton />
            </Stack>
          </Paper>
        </Stack >
      </React.Fragment>
    );
  }
}



const validateCaster = (caster) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(caster)) {
    return true;
  }
  else if (/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/.test(caster)) {
    return true;
  }
  return false;
}

const validatePort = (port) => {
  if (/^[0-9][0-9]?[0-9]?[0-9]?[0-9]?$/.test(port)) {
    if (port > 0 && port <= 65535) {
      return true;
    }
  }
  return false;
}

const cleanPort = (port) => {
  return port.replace(/[^0-9.]/g, '');
}


const validateMountpoint = (name) => {
  if (/[&\/\\#,+()$~%.'":*?<>{}]/.test(name)) {
    return false;
  }
  else if (name.length > 100) {
    return false;
  }
  else {
    return true;
  }
}

const cleanMountpoint = (name) => {
  return name.replace(" ", '_');
}
// return alias.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');
import axiosInstance from "../util/axiosAPI";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
    return {
        type: LOGIN_SUCCESS,
    };
}

export function loginError(payload) {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
}

export function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

// Logs the user out
export function logoutUser() {
    return (dispatch) => {
        dispatch(requestLogout());
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        axiosInstance.defaults.headers['Authorization'] = null;
        localStorage.setItem('authenticated', false);
        dispatch(receiveLogout());
    };
}

export function loginUser(creds) {
    return (dispatch) => {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/token/', {
                    username: creds.username,
                    password: creds.password
                }).then(response => {
                    axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    localStorage.setItem('authenticated', true);
                    dispatch(receiveLogin());
                    resolve();
                }).catch (error => {
                    console.log("Login error: ", error);
                    dispatch(loginError('Something went wrong. Try again'));
                    reject(error);
                });
            });
    }
}
